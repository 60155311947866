<template>
  <div class="m-2">
    <arq-previews-page />
    <h1>{{ process.title }}</h1>
    <div class="m-4">
      <form
        class="row"
        @submit.prevent="confirm()"
      >
        <div class="col-lg-4">
          <div v-if="contratos.length > 0">
            <label>
              Contrato
            </label>
            <b-form-select
              v-model.number="data.contrato"
              value-field="idProducto"
              text-field="descservicio"
              :options="contratos"
            />
          </div>
          <div v-else>
            <label>
              Contrato
            </label>

            <b-form-input
              v-model="data.contrato"
              value-field="idProducto"
              class="m-2"
              @input="getOptionServices(data.contrato)"
            />
          </div>
          <!-- text-field="descservicio" -->
          <!-- :options="contratos" -->
        </div>
        <div class="col-lg-4">
          <b-button
            variant="primary m-3"
            type="submit"
          >
            Consultar
          </b-button>
        </div>
      </form>
    </div>
    <div
      v-if="grafico"
      class="row "
    >
      <div class="col-lg-8 offset-1">
        <CharBar
          style="height: 250px;"
          class=" h-12"
          :chart-data="optionData"
          :options="optionsChart"
        />
      </div>
      <div class="col-lg-3">
        <h5 class="text-center">
          Consumo Promedio
        </h5>
        <apexchart
          type="radialBar"
          height="250"
          :options="chartOptions"
          :series="series"
        />
        <h5 class="text-center">
          Metros Cubicos
        </h5>
      </div>
    </div>
    {{ observer }}
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import CharBar from '@core/components/charts/ChartBar.vue'

export default {
  components: {
    CharBar,
  },
  data() {
    return {
      fechas: [],
      idService: '',
      data: [],
      grafico: false,
      // Promedio de consumo
      series: [100],
      chartOptions: {
        chart: {
          height: 50,
          type: 'radialBar',
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '60px',
                offsetY: 15,
              },
              value: {
                offsetY: 20,
                fontSize: '22px',
                formatter() {
                  return ''
                },
              },
            },
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },
          },
        },
        labels: [''],
        colors: ['#42a93e'],
      },
      consumo: {},
      // dd
      response: {},
      processParam: this.$route.params.process,
      process: {},
      // data: {},
      responsive: true,
      optionsChart: {
        legend: false,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              barPercentage: 0.4,
            },
          ],
        },
      },
      dataPersonal: {},
      optionsServices: [],
      responseUpdatePersonal: {},
      identificacionState: false,
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      messageUpdatePersonal: '',
      listProcess: [
        {
          process: 'consulta-consumos',
          title: 'Consultar consumos',
          procedure: '/consultaConsumo',
          form: [
            {
              label: 'Contrato',
              key: 'contrato',
              type: 'number',
              required: true,
            },
            {
              label: 'Servicio',
              key: 'idService',
              type: 'select-services',
              required: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState('client', ['contratos']),
    observer() {
      const { contrato } = this.data
      if (contrato) {
        this.getOptionServices(contrato)
      }
      return null
    },
    optionData() {
      return {
        options: {
          responsive: true,
          plugins: {
            legend: false,
          },
        },
        labels: [
          this.fechas[5],
          this.fechas[4],
          this.fechas[3],
          this.fechas[2],
          this.fechas[1],
          this.fechas[0],
        ],
        datasets: [
          {
            data: [
              this.consumo.consu6,
              this.consumo.consu5,
              this.consumo.consu4,
              this.consumo.consu3,
              this.consumo.consu2,
              this.consumo.consu1,
            ],
            backgroundColor: [
              '#42a93e',
              '#0076a3',
              '#42a93e',
              '#0076a3',
              '#42a93e',
              '#0076a3',
            ],
            // minBarLength: '50',
          },
        ],
      }
    },
  },
  created() {
    this.process = this.listProcess.find(el => el.process === this.processParam)
    // console.log('process', this.process)
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      let validate = false
      if (!this.dataPersonal.identificacion) {
        validate = this.checkFormValidity()
        if (!validate) {
          // console.log('blos')
          return false
        }
        return false
      }

      // Prevent modal from closing
      validate = this.checkFormValidity()
      this.identificacionState = true
      // console.log('bla')
      axios
        .post('/app/person/update', this.dataPersonal)
        .then(res => {
          if (res.data.code === 0) {
            this.responseUpdatePersonal.message = 'Sus datos han sido actualizados exitosamente.'
            this.responseUpdatePersonal.variant = 'success'
          } else {
            this.responseUpdatePersonal.message = 'Error al intentar actualizar los datos.'
            this.responseUpdatePersonal.variant = 'danger'
          }
        })
        .finally(() => {
          this.dataPersonal = {}
        })
      this.$nextTick(() => {
        this.$bvModal.hide('modal-actualizar-datos-personales')
      })
      return true
      // Trigger submit handler
    },
    checkFormValidity() {
      const valid = this.$refs.formActualizarDatosPersonales.checkValidity()
      return valid
    },
    confirm() {
      // console.log('this.data.phone ', this.data)
      this.grafico = false
      this.data.idService = this.idService
      this.data.date = new Date()
      this.sendProcedure(this.process.procedure, this.data)
        .then(res => {
          if (res.data.code === 0) {
            this.grafico = true
            this.chartOptions.labels = [res.data.object.consuprome]
            this.consumo = res.data.object
            this.fechas = []
            const meses = [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]
            res.data.object.fechasConsumos.forEach((value, index) => {
              // eslint-disable-next-line camelcase
              let name_mes = ''
              let year = ''
              if (index === 0) {
                const date = new Date(value)
                // eslint-disable-next-line camelcase
                name_mes = date.getMonth()
                year = date.getFullYear()
              }
              if (index) {
                const date = new Date(value)
                // eslint-disable-next-line camelcase
                name_mes = date.getMonth()
                year = date.getFullYear()
              }
              this.fechas.push(`${meses[name_mes]} ${year}`)
            })
            this.response = res.data
            this.response.message = res.data.msg || 'Su tramite ha sido enviado'
            this.response.variant = 'success'
          } else {
            this.response = res.data
            this.response.message = res.data.msg || 'Error al procesar su tramite'
            this.response.variant = 'danger'
          }
        })
        .catch(err => {
          this.response = err
          this.response.message = err.msg || 'Error al procesar su tramite'
          this.response.variant = 'danger'
        })
    },
    async sendProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        // console.log('formDatas', formData)
        axios
          .post(`/app/procedure${procedure}`, {
            date: formData.date,
            idService: formData.idService,
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    openActualizacionDatos() {
      this.$bvModal.show('modal-actualizar-datos-personales')
    },
    getOptionServices(contrato) {
      axios
        .get(`/app/services/fromContract?idContract=${contrato}`)
        .then(res => {
          if (res.data.code === 0) {
            this.optionsServices = res.data.list
            this.idService = res.data.object.idProductoDefault
          } else {
            this.optionsServices = []
          }
        })
    },
  },
}
</script>

<style></style>
